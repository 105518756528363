import React from 'react';
import { useAppSelector } from 'app/hooks';
import { Params, Route, Routes } from 'react-router-dom';
import HasAccess from 'permissions/HasAccess';
import { PublicOnlyRoute } from 'app/PublicOnlyRoute';
import { PrivateRoute } from 'app/PrivateRoute';
import Server from 'server/Server';
import { ROLE_TYPE_AGENT, ROLE_TYPE_ENROLLER_ADMIN, ROLE_TYPE_SHARED_APPLICANT } from 'permissions/constants/Roles';
import { Enrolments } from '../views/Enrolments/Enrolments';
import { authBypass } from 'app/api/authBypass';
import { useAuth0 } from '@auth0/auth0-react';
import { SharedEnrolments } from 'views/SharedEnrolments/SharedEnrolments';
import { useMountEffect } from 'hooks/useMountEffect';
import { SchoolRoutes } from 'app/routes/SchoolRoutes';
import { ManagementRoutes } from 'app/routes/ManagementRoutes';
import { AppLayout } from 'components/AppLayout/AppLayout';
import { useSentry } from '../hooks/analytics/useSentry';
import { useLogRocket } from '../hooks/analytics/useLogRocket';
import { useIntercom } from '../hooks/analytics/useIntercom';
import { useGoogleAnalytics } from '../hooks/analytics/useGoogleAnalytics';

const MySchools = React.lazy(() => import(/* webpackChunkName: 'schools' */ 'views/MySchools/MySchools'));
const Login = React.lazy(() => import(/* webpackChunkName: 'login' */ 'views/Auth/Login'));
const Logout = React.lazy(() => import(/* webpackChunkName: 'logout' */ 'views/Auth/Logout'));
const RegisterVerification = React.lazy(() => import(/* webpackChunkName: 'register' */ 'views/Auth/RegisterVerification'));
const Callback = React.lazy(() => import(/* webpackChunkName: 'register' */ 'views/Auth/Callback'));
const InviteAgent = React.lazy(() => import(/* webpackChunkName: 'register' */ 'views/Auth/InviteAgent'));
const RegisterAgent = React.lazy(() => import(/* webpackChunkName: 'register' */ 'views/Auth/RegisterAgent/index'));
const ErrorPage = React.lazy(() => import(/* webpackChunkName: 'errorpage' */ 'views/Error/ErrorPage'));
const Verification = React.lazy(() => import(/* webpackChunkName: 'verification' */ 'views/Auth/Verification'));
const MyProfile = React.lazy(() => import(/* webpackChunkName: 'pipeline' */ 'views/Profile/MyProfile'));

export interface VerificationRouteParams extends Params {
  token: string;
}

export interface RegisterAgentRouteParams extends Params {
  token: string;
}

export interface LoginRouteParams extends Params {
  schoolId?: string;
  token?: string;
}

export const App = () => {
  const { getAccessTokenSilently, isAuthenticated, user, logout } = useAuth0();
  const slug = useAppSelector((state) => state.school.slug);
  const schoolId = useAppSelector((state) => state.school.id);
  useSentry();
  useLogRocket();
  useIntercom();
  useGoogleAnalytics();

  authBypass.setAccessTokenSilently(getAccessTokenSilently);
  authBypass.setUser(user);
  authBypass.setIsAuthenticated(isAuthenticated);

  const isUserAllowedForSchool = () => {
    if (!isAuthenticated || !schoolId) {
      return true;
    }

    return (
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      user.userData.schoolSlugs.length &&
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      user.userData.schoolSlugs.find((s) => s === slug)
    );
  };

  useMountEffect(() => {
    if (schoolId && !isUserAllowedForSchool()) {
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
  });

  return (
    <React.Suspense fallback={<Server />}>
      <Routes>
        <Route element={<AppLayout />}>
          <Route
            path="/"
            element={
              <PublicOnlyRoute>
                <Login />
              </PublicOnlyRoute>
            }
          />
          <Route path="callback" element={<Callback />} />
          <Route path="login" element={<Login />} />
          <Route path="logout" element={<Logout />} />
          <Route
            path={`:schoolId/invitations/token/:token/login`}
            element={
              <PublicOnlyRoute>
                <Login />
              </PublicOnlyRoute>
            }
          />
          <Route
            path={`${schoolId}/invitations/token/:token/register`}
            element={
              <PublicOnlyRoute>
                <RegisterAgent slug={slug} />
              </PublicOnlyRoute>
            }
          />
          <Route
            path={`${schoolId}/invitations/token/:token`}
            element={
              <PublicOnlyRoute>
                <InviteAgent />
              </PublicOnlyRoute>
            }
          />
          <Route
            path="invitations/token/:token/register"
            element={
              <PublicOnlyRoute>
                <RegisterAgent isEnrollerRecruit />
              </PublicOnlyRoute>
            }
          />
          <Route
            path="user/verify/:token"
            element={
              <PublicOnlyRoute>
                <Verification />
              </PublicOnlyRoute>
            }
          />
          <Route
            path="register/verify"
            element={
              <PublicOnlyRoute>
                <RegisterVerification />
              </PublicOnlyRoute>
            }
          />
          <Route
            path="my-schools"
            element={
              <PrivateRoute>
                <MySchools />
              </PrivateRoute>
            }
          />
          <Route
            path="management/*"
            element={
              <PrivateRoute>
                <HasAccess for="role" name={[ROLE_TYPE_ENROLLER_ADMIN]} fallback={<ErrorPage />}>
                  <ManagementRoutes />
                </HasAccess>
              </PrivateRoute>
            }
          />
          <Route
            path="enrolments"
            element={
              <PrivateRoute>
                <HasAccess for="role" name={[ROLE_TYPE_AGENT]} fallback={<ErrorPage />}>
                  <Enrolments />
                </HasAccess>
              </PrivateRoute>
            }
          />
          <Route
            path="shared-enrolments"
            element={
              <PrivateRoute>
                <HasAccess for="role" name={[ROLE_TYPE_SHARED_APPLICANT]} fallback={<ErrorPage />}>
                  <SharedEnrolments />
                </HasAccess>
              </PrivateRoute>
            }
          />
          <Route
            path="profile"
            element={
              <PrivateRoute>
                <MyProfile />
              </PrivateRoute>
            }
          />
          <Route path=":slug/*" element={<SchoolRoutes />} />
          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
    </React.Suspense>
  );
};
